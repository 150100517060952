import React, { useEffect, useState } from "react";

import "../styles/engagement-methodology.scss";
import rsaFlag from "../images/partners/dell.svg";
import ScrollAnimation from "react-animate-on-scroll";

// Images
import mGlass from "../images/m-glass.svg";
import plus from "../images/plus.svg";
import minus from "../images/minus.svg";
import letter from "../images/letter.svg";
import handGear from "../images/hand-gear.svg";
import gearTurn from "../images/gear-turn.svg";
import mapIcon from "../images/map.svg";
import mountain from "../images/mountain.svg";
// import { img } from 'gatsby-plugin-image';

export default function EngagementMethodology() {
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(true);
  const [active3, setActive3] = useState(true);
  const [active4, setActive4] = useState(true);
  const [active5, setActive5] = useState(true);
  const [active6, setActive6] = useState(true);

  const props = {
    id1: !active1 ? "emactive" : "inactive",
    id2: !active2 ? "emactive" : "inactive",
    id3: !active3 ? "emactive" : "inactive",
    id4: !active4 ? "emactive" : "inactive",
    id5: !active5 ? "emactive" : "inactive",
    id6: !active6 ? "emactive" : "inactive",

    pmclass1: active1 ? "image-fit" : "image-fit rotate",
    pmclass2: active2 ? "image-fit" : "image-fit rotate",
    pmclass3: active3 ? "image-fit" : "image-fit rotate",
    pmclass4: active4 ? "image-fit" : "image-fit rotate",
    pmclass5: active5 ? "image-fit" : "image-fit rotate",
    pmclass6: active6 ? "image-fit" : "image-fit rotate",

    pm1: active1 ? plus : minus,
    pm2: active2 ? plus : minus,
    pm3: active3 ? plus : minus,
    pm4: active4 ? plus : minus,
    pm5: active5 ? plus : minus,
    pm6: active6 ? plus : minus,
  };

  useEffect(() => {}, []);

  return (
    <div
      id="em-wrapper"
      style={{
        // backgroundColor: 'green',
        width: "100%",
        // paddingInline: '8rem',
        paddingBlock: "2rem",
      }}
    >
      <div className="engagement-methodology-wrapper">
        {/* Assessment */}
        <ScrollAnimation animateIn="animate__fadeInLeft" duration={0.75}>
          <div className="em-steps">
            <div className="em-packet">
              <div
                className="em-title"
                id="em1"
                onClick={() => setActive1(!active1)}
              >
                <img alt="" src={mGlass} className="image-fit em-icon" />
                <p>Assessment</p>
                <img
                  alt=""
                  src={props.pm1}
                  className={props.pmclass1}
                  id="pm"
                />
              </div>
              <div
                className="em-dropdown"
                id={props.id1}
                onClick={() => setActive1(!active1)}
              >
                <h3>
                  Service <br /> Assessment
                </h3>
                <p>
                  Identify organisation’s current state through assessments &
                  interviews of “As Is” to the “To Be”.
                </p>
              </div>
              <div className="em-blue" id={props.id1}>
                <p>
                  Strategy and Assessment / <br />
                  Discovery Workshop
                </p>
              </div>
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeInBottomLeft" duration={0.75}>
          {/* Strategy */}
          <div className="em-steps">
            <div className="em-packet">
              <div
                className="em-title"
                id="em2"
                onClick={() => setActive2(!active2)}
              >
                <img alt="" src={letter} className="image-fit em-icon" />
                <p>Strategy</p>
                <img
                  alt=""
                  src={props.pm2}
                  className={props.pmclass1}
                  id="pm"
                />
              </div>
              <div
                className="em-dropdown"
                id={props.id2}
                onClick={() => setActive2(!active2)}
              >
                <h3>
                  Consult <br /> And Advise
                </h3>
                <p>
                  Review proposed architecture & provide high-level design,
                  recommendations & commercials.
                </p>
              </div>
              <div className="em-blue " id={props.id2}>
                <p>
                  Briefing <br /> Session
                </p>
              </div>
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeInBottomLeft" duration={1}>
          {/* Design */}
          <div className="em-steps">
            <div className="em-packet">
              <div
                className="em-title"
                id="em3"
                onClick={() => setActive3(!active3)}
              >
                <img alt="" src={handGear} className="image-fit em-icon" />
                <p>Design</p>
                <img
                  alt=""
                  src={props.pm3}
                  className={props.pmclass1}
                  id="pm"
                />
              </div>
              <div
                className="em-dropdown"
                id={props.id3}
                onClick={() => setActive3(!active3)}
              >
                <h3>
                  Identify <br /> And Assess
                </h3>
                <p>
                  Review proposed architecture & provide high-level design,
                  recommendations & commercials
                </p>
              </div>
              <div className="em-blue " id={props.id3}>
                <p>Design Workshops and Project Definition</p>
              </div>
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeInBottomRight" duration={1}>
          {/* Transition */}
          <div className="em-steps">
            <div className="em-packet">
              <div
                className="em-title"
                id="em4"
                onClick={() => setActive4(!active4)}
              >
                <img alt="" src={gearTurn} className="image-fit em-icon" />
                <p>Transition</p>
                <img
                  alt=""
                  src={props.pm4}
                  className={props.pmclass1}
                  id="pm"
                />
              </div>
              <div
                className="em-dropdown"
                id={props.id4}
                onClick={() => setActive4(!active4)}
              >
                <h3>
                  Integrate <br /> And migrate
                </h3>
                <p>
                  Detailed design, integration, integration and defined scope
                  aligned to solution roadmap.
                </p>
              </div>
              <div className="em-blue " id={props.id4}>
                <p>
                  Professional <br /> Services
                </p>
              </div>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="animate__fadeInBottomRight" duration={0.75}>
          {/* Operation */}
          <div className="em-steps">
            <div className="em-packet">
              <div
                className="em-title"
                id="em5"
                onClick={() => setActive5(!active5)}
              >
                <img alt="" src={mapIcon} className="image-fit em-icon" />
                <p>Operation</p>
                <img
                  alt=""
                  src={props.pm5}
                  className={props.pmclass1}
                  id="pm"
                />
              </div>
              <div
                className="em-dropdown"
                id={props.id5}
                onClick={() => setActive5(!active5)}
              >
                <h3>
                  Secure <br /> And Protect
                </h3>
                <p>
                  Implement governance risk and compliance across all core
                  business assets
                </p>
              </div>
              <div className="em-blue " id={props.id5}>
                <p>
                  Professional <br /> Services
                </p>
              </div>
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeInRight" duration={0.75}>
          {/* Optimisation */}
          <div className="em-steps">
            <div className="em-packet">
              <div
                className="em-title"
                id="em6"
                onClick={() => setActive6(!active6)}
              >
                <img alt="" src={mountain} className="image-fit em-icon" />
                <p>Optimisation</p>
                <img
                  alt=""
                  src={props.pm6}
                  className={props.pmclass1}
                  id="pm"
                />
              </div>
              <div
                className="em-dropdown"
                id={props.id6}
                onClick={() => setActive6(!active6)}
              >
                <h3>
                  Operationalise <br /> Handover
                </h3>
                <p>
                  Project handover, knowledge transfer. Optimise, ensure
                  compliance, capacity and performance
                </p>
              </div>
              <div className="em-blue " id={props.id6}>
                <p>
                  Cloud and Managed <br /> Services
                </p>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
}
