import React, { useEffect, useState } from "react";

import "../styles/tabs-component.scss";
import "../styles/engagement-methodology.scss";

import plus from "../images/plus.svg";
import minus from "../images/minus.svg";
// import { img } from 'gatsby-plugin-image';

export default function Tabs(props: any) {
  const [activeTab, setActiveTab] = useState(0);

  const [activeTabA, setActiveTabA] = useState(1);
  const [activeTabB, setActiveTabB] = useState(1);

  // useEffect(() => {
  //   console.log(activeTab);
  // }, [activeTab]);

  props = {
    id1: activeTab == 0 ? "active" : "inactive",
    id2: activeTab == 1 ? "active" : "inactive",
    id3: activeTab == 2 ? "active" : "inactive",
    id4: activeTab == 3 ? "active" : "inactive",
    id5: activeTab == 4 ? "active" : "inactive",

    idA1: activeTabA == 1 ? "active" : "inactive",
    idA2: activeTabA == 2 ? "active" : "inactive",
    idA3: activeTabA == 3 ? "active" : "inactive",
    idA4: activeTabA == 4 ? "active" : "inactive",
    idA5: activeTabA == 5 ? "active" : "inactive",

    idB1: activeTabB == 1 ? "active" : "inactive",
    idB2: activeTabB == 2 ? "active" : "inactive",

    A1: activeTabA == 1 ? minus : plus,
    A2: activeTabA == 2 ? minus : plus,
    A3: activeTabA == 3 ? minus : plus,
    A4: activeTabA == 4 ? minus : plus,
    A5: activeTabA == 5 ? minus : plus,

    B1: activeTabB == 1 ? minus : plus,
    B2: activeTabB == 2 ? minus : plus,
  };

  return (
    <div id="tabs-wrapper">
      <div className="w-max">
        <div className="tabs-wrap">
          <h3 onClick={() => setActiveTab(0)} id={props.id1}>
            Multi Cloud
          </h3>
          <h3 onClick={() => setActiveTab(1)} id={props.id2}>
            Modern Applications
          </h3>
          <h3 onClick={() => setActiveTab(2)} id={props.id3}>
            Software Defined Networking
          </h3>
          <h3 onClick={() => setActiveTab(3)} id={props.id4}>
            Digital Workspace
          </h3>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          maxWidth: "100vw",
          width: "100vw",
          paddingBlock: "5rem",
          // paddingInline: "10rem",
          backgroundColor: "var(--white)",
        }}
        id="tab-content"
      >
        <div className="w-max">
          {activeTab == 0 ? (
            <>
              <div className="list-wrap">
                <ul>
                  <li
                    className={props.idA1}
                    onClick={() => {
                      setActiveTabA(1);
                    }}
                  >
                    <img alt="" src={props.A1} id="comp-sign" />
                    Data Center Virtualisation
                  </li>
                  <li
                    className={props.idA2}
                    onClick={() => {
                      setActiveTabA(2);
                    }}
                  >
                    <img alt="" src={props.A2} id="comp-sign" />
                    Cloud Management and Automation
                  </li>
                  <li
                    className={props.idA3}
                    onClick={() => {
                      setActiveTabA(3);
                    }}
                  >
                    <img alt="" src={props.A3} id="comp-sign" />
                    VMware Cloud Foundation
                  </li>
                  <li
                    className={props.idA5}
                    onClick={() => {
                      setActiveTabA(5);
                    }}
                  >
                    <img alt="" src={props.A5} id="comp-sign" />
                    Azure VMware solution
                  </li>
                  <li
                    className={props.idA4}
                    onClick={() => {
                      setActiveTabA(4);
                    }}
                  >
                    <img alt="" src={props.A4} id="comp-sign" />
                    VMware Cloud on AWS
                  </li>
                </ul>
              </div>
              <div className="description-wrap">
                {activeTabA == 1 ? (
                  <p>
                    STS Professional Services have competency and certification
                    to Deliver VMware vSphere environments and digital
                    infrastructure services, with a deep understanding of server
                    and storage virtualisation, hyper-converged infrastructure,
                    virtual networking and security.
                  </p>
                ) : (
                  <></>
                )}
                {activeTabA == 2 ? (
                  <p>
                    STS Professional Services have competency and certification
                    to deliver VMware Cloud Management and Automation solutions.
                  </p>
                ) : (
                  <></>
                )}
                {activeTabA == 3 ? (
                  <p>
                    STS Professional Services have competency and certification
                    for the deployment of VMware Cloud Foundation (VCF) and the
                    adoption of VMware Cloud Foundation solutions, with a deep
                    understanding of cloud migration, management and operations.
                  </p>
                ) : (
                  <></>
                )}
                {activeTabA == 4 ? (
                  <p>
                    STS Professional Services have competency and certification
                    to deliver hybrid cloud solutions based on VMware Cloud on
                    AWS, that delivers an on-demand service, enabling customers
                    to run applications across a VMware Cloud Infrastructure
                    environment with access to a broad range of AWS services.
                    Powered by VMware Cloud Foundation, this service provides an
                    operationally consistent and familiar way to run, manage and
                    secure applications in a hybrid IT environment.
                  </p>
                ) : (
                  <></>
                )}
                {activeTabA == 5 ? (
                  <p>
                    Azure VMware Solution enables a fast path to the cloud,
                    seamlessly migrating or extending existing VMware workloads
                    from on-premises environments to Azure without the cost,
                    effort, or risk of re-architecting applications or retooling
                    operations. Build, run, manage, and secure applications
                    across VMware environments and Microsoft Azure while
                    leveraging familiar and established VMware tools, skills,
                    and processes.
                  </p>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
          {/* MODERN APPLICATIONS */}
          {activeTab == 1 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                maxWidth: "100vw",
                paddingBlock: "5rem",
                paddingInline: "10rem",
                backgroundColor: "var(--white)",
              }}
              id="tab-content"
            >
              <div className="list-wrap">
                <ul>
                  <li className="active">
                    <img alt="" src={minus} id="comp-sign" />
                    Cloud Native
                  </li>
                </ul>
              </div>
              <div className="description-wrap">
                <p>
                  STS Professional Services have competency and certification to
                  run and manage VMware Tanzu Modern Application Platform
                  solutions, with an understanding of Kubernetes administration
                  core concepts, and proficiency in networking, security and
                  application lifecycle management to ensure the continuous
                  delivery of applications on Kubernetes-based platforms.
                </p>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* software defined networking */}
          {activeTab == 2 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                maxWidth: "100vw",
                paddingBlock: "5rem",
                paddingInline: "10rem",
                backgroundColor: "var(--white)",
              }}
              id="tab-content"
            >
              <div className="list-wrap">
                <ul>
                  <li
                    className={props.idB1}
                    onClick={() => {
                      setActiveTabB(1);
                    }}
                  >
                    <img alt="" src={props.B1} id="comp-sign" />
                    Network Virtualisation
                  </li>
                  <li
                    className={props.idB2}
                    onClick={() => {
                      setActiveTabB(2);
                    }}
                  >
                    <img alt="" src={props.B2} id="comp-sign" />
                    Software Defined Wide Area Network
                  </li>
                </ul>
              </div>
              <div className="description-wrap">
                {activeTabB == 1 ? (
                  <p>
                    STS Professional Services have competency and certification
                    to Deliver VMware NSX® environments and services. Our NSX
                    engagement model offers a completely new operational model
                    for networking that forms the foundation of the
                    Software-Defined Data Center (SDDC) and enables the creation
                    of entire networks in software, embedding these networks in
                    the hypervisor layer and abstracted from the underlying
                    physical hardware.
                  </p>
                ) : (
                  <></>
                )}
                {activeTabB == 2 ? (
                  <p>
                    STS Professional Services have competency and certification
                    to design, deploy and manage VMware SD-WAN to ensure
                    consistent and standardised lifecycle services using
                    orchestration methodologies.
                  </p>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* digital workspace */}
          {activeTab == 3 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                maxWidth: "100vw",
                paddingBlock: "5rem",
                paddingInline: "10rem",
                backgroundColor: "var(--white)",
              }}
              id="tab-content"
            >
              <div className="list-wrap">
                <ul>
                  <li className="active">
                    <img alt="" src={minus} id="comp-sign" />
                    Digital Workspace
                  </li>
                </ul>
              </div>
              <div className="description-wrap">
                <p>
                  STS Professional Services have competency and certification to
                  design, deliver and support the ongoing management of customer
                  apps, data and virtual desktop solutions whether on-premise or
                  cloud delivered, leveraging VMware Horizon and VMware
                  Workspace ONE end-user computing technologies.
                </p>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
