import * as React from "react";
import Layout from "../components/Layout";
import PartnerCarousel from "../components/carousels/PartnerCarousel";

//Images
import trust1 from "../images/Professional Services/10f2.png";
import trust2 from "../images/Professional Services/10f3.png";

import dell from "../images/partners/dell.svg";
import vmware from "../images/partners/vmware.svg";
import veeam from "../images/partners/veeam.svg";

import se1 from "../images/se1.svg";
import se2 from "../images/se2.svg";
import se3 from "../images/se3.svg";
import se4 from "../images/se4.svg";

import ScrollAnimation from "react-animate-on-scroll";

import profser1 from "../images/profser1.png";
import profser2 from "../images/profser2.png";
import profser3 from "../images/profser3.png";
import profser4 from "../images/profser4.png";
import profser5 from "../images/profser5.png";
import profser6 from "../images/profser6.png";

//CSS
import "../styles/index.scss";
import "../styles/grids.scss";
import "../styles/particles.scss";
import "../styles/buttons.scss";
import "../styles/elements.scss";
import ContactForm from "../components/ContactForm";
import Tabs from "../components/Tabs";
import EngagementMethodology from "../components/EngagementMethodology";
import SEO from "../components/seo";
// import { img } from 'gatsby-plugin-image';

// markup
const ProfessionalServicesPage = () => {
  const props = {
    seoTitle: "Professional Services",
    seoDescription:
      "Strategix Technology Solutions is certified to provide Veeam, Dell and VMware professional services, ensuring successful digital transformation initiatives.",
  };

  return (
    <Layout>
      <SEO
        title={props.seoTitle}
        description={props.seoDescription}
        // keywords={[]}
        lang="en-gb"
      />
      <main>
        {/* Home Hero */}
        <section>
          <div className="hero" id="professionalserv">
            <div className="flex-col-c">
              <ScrollAnimation animateIn="animate__fadeIn" duration={0.75}>
                <h1 className="fade-intro">Professional Services</h1>
                <p className="fade-intro">
                  Enable digital transformation and unlock business value in
                  your invested software.
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </section>

        {/* Our Range of Managed Services */}

        <section className="half flex-col-c">
          <div style={{ maxWidth: "100vw" }}>
            <div className="w-max">
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                <h1
                  style={{ textAlign: "center", paddingBottom: "1rem" }}
                  className="fade-in-up"
                >
                  Our Range of Professional Services
                </h1>
                <h2
                  style={{
                    textAlign: "center",
                    marginBottom: "0rem",
                    color: "var(--red)",
                  }}
                >
                  Our Professional Services Team Provide:
                </h2>
              </ScrollAnimation>
              <div className="prof-ser-menu">
                <div className="service-wrap">
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={0.75}
                  >
                    <div className="service-wrap-image">
                      <img alt="" src={profser1} className="image-fit" />
                      <p>Leading Skills and Best Practice.</p>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={0.75}
                    delay={100}
                  >
                    <div className="service-wrap-image">
                      <img alt="" src={profser2} className="image-fit" />
                      <p>Consultancy, Assessment and Gap Analysis.</p>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={0.75}
                    delay={200}
                  >
                    <div className="service-wrap-image">
                      <img alt="" src={profser3} className="image-fit" />
                      <p>Architectural and Functional Roadmaps.</p>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={0.75}
                    delay={300}
                  >
                    <div className="service-wrap-image">
                      <img alt="" src={profser4} className="image-fit" />
                      <p>Comprehensive Solution Design.</p>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={0.75}
                    delay={400}
                  >
                    <div className="service-wrap-image">
                      <img alt="" src={profser5} className="image-fit" />
                      <p>Definition of Solution Prerequisites.</p>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={0.75}
                    delay={500}
                  >
                    <div className="service-wrap-image">
                      <img alt="" src={profser6} className="image-fit" />
                      <p>A Fixed, Predictable Cost Model.</p>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>

              <div className="trusted-wrap-over">
                <div className="trusted-wrap">
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={0.75}
                  >
                    <h1
                      style={{
                        textAlign: "center",
                        marginBottom: "2rem",
                        color: "var(--black)",
                      }}
                    >
                      Trusted By The Best In The Industry
                    </h1>
                    <p style={{ textAlign: "center" }}>
                      South Africa's finest; the only partner that can provide:
                    </p>
                  </ScrollAnimation>
                  <div className="trusted">
                    <div className="trusted-info">
                      <ScrollAnimation
                        animateIn="animate__fadeInLeft"
                        duration={0.75}
                      >
                        <div
                          style={{
                            display: "flex",
                            padding: "20px",
                            gap: "1em",
                          }}
                        >
                          <img
                            alt=""
                            src={trust1}
                            className="image-fit"
                            style={{
                              transform: "scale(0.75)",
                            }}
                          />
                          <p>Dell Professional Services Partners</p>
                        </div>
                        <div className="full-width flex-col-c">
                          <img alt="" src={dell} className="image-fit" />
                        </div>
                      </ScrollAnimation>
                    </div>
                    <div className="trusted-info">
                      <ScrollAnimation
                        animateIn="animate__fadeInUp"
                        duration={0.75}
                      >
                        <div
                          style={{
                            display: "flex",
                            padding: "20px",
                            gap: "1em",
                          }}
                        >
                          <img alt="" src={trust2} className="image-fit" />
                          <p>VMware Professional Services Partners</p>
                        </div>
                        <div className="full-width flex-col-c">
                          <img
                            alt=""
                            src={vmware}
                            className="image-fit image-anim"
                            style={{
                              transform: "scale(1.35)",
                              marginTop: "-20px",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                      </ScrollAnimation>
                    </div>
                    <div className="trusted-info">
                      <ScrollAnimation
                        animateIn="animate__fadeInRight"
                        duration={0.75}
                      >
                        <div
                          style={{
                            display: "flex",
                            padding: "20px",
                            gap: "1em",
                          }}
                        >
                          <img
                            alt=""
                            src={trust1}
                            className="image-fit"
                            style={{
                              transform: "scale(0.75)",
                            }}
                          />
                          <p>Veeam Professional Services Partners</p>
                        </div>
                        <div className="full-width flex-col-c">
                          <img alt="" src={veeam} className="image-fit" />
                        </div>
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Engagement Methodology */}
        <section
          style={{
            backgroundColor: "var(--grey)",
            paddingTop: "5rem",
            minHeight: "50vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="w-max">
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <h1
                style={{ textAlign: "center", paddingBottom: "2rem" }}
                className="fade-in-up"
                id="engagement-methodology"
              >
                Our Engagement Methodology
              </h1>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <p
                id="em-description"
                style={{
                  textAlign: "center",
                  paddingBottom: "2rem",
                  paddingInline: "10rem",
                }}
              >
                Our engagement methodology provides a structured roadmap to
                customer engagement, ensuring projects are completed in line
                with the required timescales and business drivers, while
                minimising project risk.
              </p>
            </ScrollAnimation>
            <div className="d-flex" style={{ width: "100%", height: "100%" }}>
              <EngagementMethodology />
            </div>
          </div>
        </section>
        {/* Competencies */}
        <a id="vmware-professional-services-competencies" />
        <section
          id="ps-tabs"
          style={{
            backgroundColor: "var(--grey)",
            paddingTop: "5rem",
            minHeight: "50vh",
            width: "100%",
          }}
        >
          <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
            <h1
              style={{ textAlign: "center", paddingBottom: "2rem" }}
              className="fade-in-up"
            >
              VMware Professional Services Competencies
            </h1>
            <p style={{ textAlign: "center", paddingBottom: "2rem" }}>
              Our certified capabilities ensure project delivery on time, within
              budget and exceeding expectations.
            </p>
          </ScrollAnimation>
          <div className="d-flex" style={{ width: "100%", height: "100%" }}>
            <div>
              <Tabs />

              <h2
                style={{
                  color: "var(--red)",
                  textAlign: "center",
                  backgroundColor: "#fff",
                  paddingBottom: "2rem",
                  paddingInline: "2rem",
                  margin: "0",
                  width: "100vw",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span className="w-max">
                  Our Professional Services Competencies Ensure That Our Reach
                  Across Your ICT Landscape Is Broad And Impactful.
                </span>
              </h2>
            </div>
          </div>
        </section>

        {/* STS Strategic Elements*/}
        <a id="our-strategic-elements" />
        <section className="half">
          <div className="numbers-wrapper half" id="strategic-elements">
            <div className="w-max">
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                <h1 style={{ textAlign: "center" }} className="fade-in-up">
                  4 Strategic Elements
                </h1>
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                <p style={{ textAlign: "center" }}>
                  Principles that govern project delivery and solutions.
                </p>
              </ScrollAnimation>
              <div className="grid-column-13" style={{ marginLeft: "2.5rem" }}>
                <div
                  className="span-3 numbers flex-col-c fade-in-up"
                  style={{ justifyContent: "flex-start" }}
                >
                  <ScrollAnimation
                    animateIn="animate__fadeInLeft"
                    duration={0.75}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        alignItems: "flex-start",
                      }}
                    >
                      <h1 style={{ color: "var(--red)" }}>01</h1>
                      <img alt="" src={se1} className="image-fit strat-el" />
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={0.75}
                    delay={50}
                  >
                    <p>
                      <strong>Project Success</strong>
                      <br />
                      <br />
                      We deliver solutions into production with clear milestones
                      throughout. Our shared-risk, fixed-price delivery model
                      ensures an accelerated time to value.
                    </p>
                  </ScrollAnimation>
                </div>
                <div
                  className="span-3 numbers flex-col-c fade-in-up"
                  style={{ justifyContent: "flex-start" }}
                >
                  <ScrollAnimation
                    animateIn="animate__fadeInLeft"
                    duration={0.75}
                    delay={150}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        alignItems: "flex-start",
                      }}
                    >
                      <h1 style={{ color: "var(--red)" }}>02</h1>
                      <img alt="" src={se2} className="image-fit strat-el" />
                    </div>{" "}
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={0.75}
                    delay={200}
                  >
                    <p>
                      <strong>Integration Expertise</strong>
                      <br />
                      <br />
                      Our knowledge of complex environments is leveraged to
                      ensure integration of existing technologies, architectures
                      and business processes.
                    </p>
                  </ScrollAnimation>
                </div>
                <div
                  className="span-3 numbers flex-col-c fade-in-up"
                  style={{ justifyContent: "flex-start" }}
                >
                  <ScrollAnimation
                    animateIn="animate__fadeInLeft"
                    duration={0.75}
                    delay={250}
                  >
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        alignItems: "flex-start",
                      }}
                    >
                      <h1 style={{ color: "var(--red)" }}>03</h1>
                      <img alt="" src={se3} className="image-fit strat-el" />
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={0.75}
                    delay={300}
                  >
                    <p>
                      <strong>Commercial Protection</strong>
                      <br />
                      <br />
                      We maximise investment in complementary technologies.
                      Linking solutions to a tangible business case ensures a
                      return on your investment.
                    </p>
                  </ScrollAnimation>
                </div>
                <div
                  className="span-3 numbers flex-col-c fade-in-up"
                  style={{ justifyContent: "flex-start" }}
                >
                  <ScrollAnimation
                    animateIn="animate__fadeInLeft"
                    duration={0.75}
                    delay={350}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        alignItems: "flex-start",
                      }}
                    >
                      <h1 style={{ color: "var(--red)" }}>04</h1>
                      <img alt="" src={se4} className="image-fit strat-el" />
                    </div>{" "}
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={0.75}
                    delay={400}
                  >
                    <p>
                      <strong>Transitioning of Skills</strong>
                      <br />
                      <br />
                      We ensure sustainability through a blended delivery model.
                      This allows for a transfer of expertise and skills
                      enablement for customers.
                    </p>
                  </ScrollAnimation>
                </div>
                {/* <div className="span-8">
                <div className="numbers-text">
                  <div
                    style={{
                      display: 'flex',
                      backgroundColor: 'var(--grey)',
                      borderRadius: '20px',
                      paddingInline: '2.5rem',
                      paddingBlock: '1rem',
                      alignItems: 'center',
                    }}
                  >
                    <div className="sixty-width pad-text">
                      <h2>
                        A proven global track record of business success, 17+
                        years in the making.{' '}
                      </h2>
                    </div>
                    <div className="forty-width">
                      <img alt='' src={bbbee} className="image-fit" id="bbbee" />
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </section>

        {/* Partners */}
        <section style={{ minHeight: "50vh" }}>
          <PartnerCarousel />
        </section>

        {/* Contact */}
        <section className="half">
          <ContactForm />
        </section>
      </main>
    </Layout>
  );
};

export default ProfessionalServicesPage;
